import React, { useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { QuestionLabel } from '../../components/QuestionLabel';
import { QuestionOption } from '../interfaces';
import { QuestionProps } from './interfaces';
import { IconButton } from '../../components/IconButton';

import close from '../../assets/icons/icon-x-white.png';
import closeHover from '../../assets/icons/icon-x-orange.png';
import closeDisabled from '../../assets/icons/icon-x-light-grey.png';

export const Dropdown = React.memo((props: QuestionProps) => {
  const { question, answers, focus, onNext, onChange, show, handleClose, handleShow, userName } = props;
  const { id, options, showUserName } = question;

  const value: QuestionOption[] = answers || [options[0]];

  const onNextAfterValidation = useCallback(() => {
    if (value.length > 0 && value[0].id !== 1) {
      onNext(id);
    }
  }, [id, onNext, value]);

  useEffect(() => {
    if (!focus) {
      return;
    }

    document.addEventListener('keypress', listener);
    return () => document.removeEventListener('keypress', listener);

    function listener(evt: KeyboardEvent) {
      if (evt.key === 'Enter') {
        onNextAfterValidation();
      }
    }
  }, [focus, onNextAfterValidation]);

  const onChangeOption = useCallback((evt: React.ChangeEvent<HTMLSelectElement>) => {
    if (evt.target.value === '1') {
      return;
    }

    const selectedOption = options.find((x) => x.id === parseInt(evt.target.value));

    if (selectedOption) {
      onChange(id, [{ id: selectedOption.id, value: selectedOption.value }]);
    }
  }, [id, options, onChange]);

  return (
    <div className="quiz-content">
      <div className="quiz-content-question">
        { userName && showUserName && `Nice to meet you, ${userName}`}
        <QuestionLabel question={question} />
        <div className="dropdown-container d-flex flex-column align-items-center">
          <div className="position-relative">
            <select className="occupation-container" name="occupation-container" id="occupation-container" value={value[0].id} onChange={onChangeOption}>
              {options.map((option, idx) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.value}
                  </option>
                );
              })}
            </select>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="quiz-content-actions">
        <div className="my-3">
          <div className="row align-items-center">
            <div className="col-auto">
              <button className="btn-next" disabled={value.length <= 0 || value[0].id === 1} type="button" onClick={onNextAfterValidation}>
               Next →
              </button>
            </div>
          </div>
        </div>
        {question.explanationText && (
          <>
            <Button className="btn-show-modal dark my-0 mx-auto mx-sm-0" onClick={handleShow} variant="link">{question.explanationText}</Button>
            <Modal className="explanation-modal" centered show={show} onHide={handleClose}>
              <Modal.Body className="bg-emperor text-white">
                <IconButton
                  classNames="btn-close-modal close-modal"
                  icon={close}
                  hoverIcon={closeHover}
                  disabled={false}
                  disabledIcon={closeDisabled}
                  onClickAction={handleClose}
                  btnAlt="Close"
                />
                <div className="explanation-text">
                  {question.explanationContent}
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
});
