import React from 'react';
import Button from 'react-bootstrap/Button';

import arrowDown from '../assets/icons/arrow-down.svg';
import { IntroProps } from './interfaces';

export const IntroQuiz3 = React.memo((props: IntroProps) => {
  const { onShowQuiz, handleShow } = props;

  return (
    <div className="container text-center text-gutters">
      <h1 className="text-uppercase title-text text-gold text-bold">Visualizing Riyadh</h1>
      <div className="intro-text">
      In this exercise, you’ll be asked to select words, colors, and images that you believe best capture the spirit of Riyadh. Your choices will be compiled to create a “Riyadh Moodboard,” that creates a visual representation of how you see our city.
      </div>
      <div>
        <Button className="btn-show-modal" onClick={handleShow}>
          Learn More About This Exercise <span className="play-symbol"></span>
        </Button>
      </div>
      <div> 
        <div className="btn-start-quiz-container" onClick={onShowQuiz}>
          <div>Click Here To Begin</div>
          <button className="btn-start-quiz mt-3" type="button">
            <img src={arrowDown} width="70" alt="Down arrow to start quiz"/>
          </button>
        </div>
      </div>
    </div>
  );
});
