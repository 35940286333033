import React, { useEffect, useRef, useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextareaAutosize from 'react-autosize-textarea';

import { QuestionLabel } from '../../components/QuestionLabel';
import { QuestionOption } from '../interfaces';
import { QuestionProps } from './interfaces';
import { IconButton } from '../../components/IconButton';

import close from '../../assets/icons/icon-x-white.png';
import closeHover from '../../assets/icons/icon-x-orange.png';
import closeDisabled from '../../assets/icons/icon-x-light-grey.png';

export const Input = React.memo((props: QuestionProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { question, answers, focus, onChange, onNext, show, handleShow, handleClose, prevQuestionLabel, prevQuestionAnswer } = props;
  const { id, placeholder, options } = question;

  const [charLength, setCharLength] = useState<number>(0);

  const option = options[0];
  const value: QuestionOption[] = answers || [{ id: option.id, value: '' }];

  const onNextAfterValidation = useCallback(() => {
    if (value[0].value.length > 0) {
      onNext(id);
    }
  }, [id, value, onNext]);

  useEffect(() => {
    if (!focus) {
      return;
    }

    document.addEventListener('keypress', listener);
    return () => document.removeEventListener('keypress', listener);

    function listener(evt: KeyboardEvent) {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        if (inputRef.current) {
          inputRef.current.blur();
        }
        onNextAfterValidation();
      }
    }
  }, [focus, onNextAfterValidation]);

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, inputRef]);

  const onInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCharLength(evt.target.value.length);
      onChange(question.id, [{ id: option.id, value: evt.target.value }]);
    },
    [question, option, onChange],
  );

  const inputId = `question-${id}`;
  return (
    <div className="quiz-content">
      <div className="quiz-content-question">
        {question.showPrevQuestion && (
          <div className="prev-question-container">
            <label>
              <div className="question-heading">
                <div className="heading-text">{prevQuestionLabel}</div>
              </div>
            </label>
            <div className="prev-answer">
              {prevQuestionAnswer}
            </div>
          </div>
        )}
        <QuestionLabel question={question} />
        <div className="input-container d-flex flex-column align-items-center">
          <div className="position-relative w-100">
            <TextareaAutosize
              id={inputId}
              rows={1}
              className="input-quiz"
              ref={inputRef}
              value={value[0].value}
              placeholder={placeholder}
              onChange={onInputChange}
              maxLength={500}
            />
            <div className="line" />
            <div className="after" style={{visibility: value && value[0].value.length > 0 ? 'visible' : 'hidden'}}>Press RETURN &nbsp;</div>
            <div className="char-count" style={{visibility: value && value[0].value.length > 0 ? 'visible' : 'hidden'}}>{question.showCount && question.maxLength ? `${charLength}/${question.maxLength || 0}` : ''}&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="quiz-content-actions">
        <button className="btn-next my-3" disabled={value[0].value.length <= 0} type="button" onClick={onNextAfterValidation}>
         Next →
        </button>
        {question.explanationText && (
          <>
            <Button className="btn-show-modal dark my-0 mx-auto mx-sm-0" onClick={handleShow} variant="link">{question.explanationText}</Button>
            <Modal className="explanation-modal" centered show={show} onHide={handleClose}>
              <Modal.Body className="bg-emperor text-white">
              <IconButton
                classNames="btn-close-modal close-modal"
                icon={close}
                hoverIcon={closeHover}
                disabled={false}
                disabledIcon={closeDisabled}
                onClickAction={handleClose}
                btnAlt="Close"
              />
                <div className="explanation-text">
                  {question.explanationContent}
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
});
