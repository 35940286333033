import React, {useCallback, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import { Header } from './components/Header';
import { IntroWelcome } from './intros/IntroWelcome';
import { IntroQuiz1 } from './intros/IntroQuiz1';
import { IntroQuiz2 } from './intros/IntroQuiz2';
import { IntroQuiz3 } from './intros/IntroQuiz3';
import { IntroSummary } from './intros/IntroSummary';
import { QuestionOption } from './quiz/interfaces';
import { QuizPanel } from './quiz/Quiz';
import { Quiz } from './quiz/interfaces';
import { IconButton } from './components/IconButton';

import { WelcomeQuiz, Quiz1, Quiz2, Quiz3 } from './quiz/data';
import close from './assets/icons/icon-x-white.png';
import closeHover from './assets/icons/icon-x-orange.png';
import closeDisabled from './assets/icons/icon-x-light-grey.png';

enum Pages {
  Welcome,
  QuizOne,
  QuizTwo,
  QuizThree,
  Summary,
}

export interface AllAnswers {
  [pageNumber: number]: QuizAnswers;
}

export interface QuizAnswers {
  [questionNumber: number]: QuestionOption[];
}

function App() {
  const [currentPage, setCurrentPage] = useState(Pages.Welcome);

  const [showQuiz, setShowQuiz] = useState(false);

  const [answers, setAnswers] = useState<AllAnswers>({
    [Pages.Welcome]: {},
    [Pages.QuizOne]: {},
    [Pages.QuizTwo]: {},
    [Pages.QuizThree]: {},
  });

  const [progression, setProgression] = useState<number>(0);

  const [show, setShow] = useState(false);

  const [watched, setWatched] = useState({
    [Pages.Welcome]: false,
    [Pages.QuizOne]: false,
    [Pages.QuizTwo]: false,
    [Pages.QuizThree]: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {

  // Slightly update progress bar after welcome video plays
  if (currentPage === Pages.Welcome && progression === 0) {
    setProgression(3)
  }

  setWatched({
    ...watched,
    [currentPage]: true,
  });
  setShow(true);
};

  const onAnswersChange = useCallback((newAnswers: QuizAnswers) => {
    setAnswers({
      ...answers,
      [currentPage]: newAnswers
    })
  }, [answers, currentPage]);

  const onPageNext = () => {
    // Ensure progress set to 100 on quiz submission
    if (currentPage === 3) setProgression(100);
    setCurrentPage(currentPage + 1);
    setShowQuiz(false);
  };

  let introContent, backgroundImage;
  switch (currentPage) {
    case Pages.Welcome:
      introContent = <IntroWelcome onShowQuiz={() => setShowQuiz(true)} handleShow={handleShow} watched={watched[Pages.Welcome]} />;
      break;
    case Pages.QuizOne:
      introContent = <IntroQuiz1 onShowQuiz={() => setShowQuiz(true)} handleShow={handleShow} watched={watched[Pages.QuizOne]} />;
      break;
    case Pages.QuizTwo:
      introContent = <IntroQuiz2 onShowQuiz={() => setShowQuiz(true)} handleShow={handleShow} watched={watched[Pages.QuizTwo]} />;
      break;
    case Pages.QuizThree:
      introContent = <IntroQuiz3 onShowQuiz={() => setShowQuiz(true)} handleShow={handleShow} watched={watched[Pages.QuizThree]} />;
      break;
    case Pages.Summary:
      introContent = <IntroSummary answers={answers} />;
      backgroundImage = require('./assets/background-images/thankyou.jpg');
      break;
    default:
      introContent = null;
      backgroundImage = WelcomeQuiz?.backgroundImage;
  }

  let quiz: Quiz | undefined;
  switch (currentPage) {
    case Pages.Welcome:
      quiz = WelcomeQuiz;
      backgroundImage = WelcomeQuiz?.backgroundImage;
      break;
    case Pages.QuizOne:
      quiz = Quiz1;
      backgroundImage = Quiz1?.backgroundImage;
      break;
    case Pages.QuizTwo:
      quiz = Quiz2;
      backgroundImage = Quiz2?.backgroundImage;
      break;
    case Pages.QuizThree:
      quiz = Quiz3;
      backgroundImage = Quiz3?.backgroundImage;
      break;
    default:
      quiz = undefined;
  }

  const quizId = currentPage + 1;

  const updateProgression = () => {

    const quizQuestions = quiz?.questions || [];

    const currentQuiz = answers ? answers[quizId - 1] : [];

    setProgression((((quizId - 1) / 4) * 100) + (((Object.keys(currentQuiz).length / quizQuestions.length) * 100) / 4))
  }

  return (
    <div className={`background ${showQuiz ? 'open' : ''}`} style={{backgroundImage: `url(${backgroundImage})`}}>
      <Header quizId={quizId} progression={progression} />
      <div className="fullscreen content">{introContent}</div>
      <div className="fullscreen quiz">
        {quizId < 5 && (
          <QuizPanel
            quiz={quiz}
            allAnswers={answers}
            currentPage={currentPage}
            onAnswersChange={onAnswersChange}
            onNextPage={onPageNext}
            updateProgression={updateProgression}
            showVideo={handleShow}
          />
        )}
      </div>
      <Modal className="video-modal" show={show} onHide={handleClose}>
        <Modal.Body>
          <IconButton
            classNames="btn-close-modal close-modal"
            icon={close} hoverIcon={closeHover}
            disabled={false}
            disabledIcon={closeDisabled}
            onClickAction={handleClose}
            btnAlt="Close"
          />
          <iframe className="video-modal-iframe" title="video-modal" src={quiz?.videoUrl}></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
