import React, { useState } from 'react';

interface IconButtonProps {
  icon: string;
  hoverIcon: string;
  disabled: boolean;
  disabledIcon: string;
  onClickAction: () => void;
  classNames?: string;
  btnAlt: string
}

export const IconButton = React.memo((props: IconButtonProps) => {
  const {icon, hoverIcon, disabledIcon, disabled, onClickAction, classNames, btnAlt} = props;
  const [isHover, setIsHover] = useState<boolean>(false);

  const displayIcon = disabled ? disabledIcon : isHover ? hoverIcon : icon;

  return (
    <>
      <button
        className={`btn-icon ${classNames}`}
        onClick={onClickAction}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <img src={displayIcon} alt={btnAlt} />
      </button>
    </>
  );
});
