import React, {useEffect, useState} from 'react';
import { QuizAnswers } from '../App';
import { QuestionOption } from '../quiz/interfaces';

interface MoodboardProps {
  answers: QuizAnswers;
}

export const Moodboard = (props: MoodboardProps) => {
  const { answers } = props;

  const [moodboard, setMoodboard] = useState<any>({});

  useEffect(() => {
    if (answers[3] && answers[2] && answers[1]) {
      
      const sortedImages: QuestionOption[] = [...answers[3]];
      
      sortedImages.sort((a: QuestionOption, b: QuestionOption) => {
        return a.id - b.id;
      })

      setMoodboard({
        imageBg1: sortedImages[1].value,
        imageBg2: sortedImages[2].value,
        imageBg3: sortedImages[0].value,
        colors: [...answers[2]],
        words: [...answers[1]],
      })
    }
  }, [answers])

  return (
    <div className='moodboard'>
      <div className="row">
        <div className="col-4">
          <div className="row image-bg" style={{backgroundImage: `url(${moodboard.imageBg1})`}}></div>
          <div className="row image-bg" style={{backgroundImage: `url(${moodboard.imageBg2})`}}></div>
        </div>
        <div className="col-8">
          <div className="row image-bg main" style={{backgroundImage: `url(${moodboard.imageBg3})`}}></div>
        </div>
      </div>
      {moodboard.colors && moodboard.colors.map((option: QuestionOption) => {
        return (
          <div className="row color-row" style={{backgroundColor: option.hexColor}} key={option.id}></div>
        )
      })}
      <div className="words-overlay">
        <div className="words-container">

          {moodboard.words && moodboard.words.map((option: QuestionOption) => {
            return (
              <div className="row" key={option.id}>
                <div className="col text-light">
                  <div className="associated-words">
                    <svg viewBox="0 0 180 24">
                      <text x="0" y="15" color="white">{option.value}</text>
                    </svg>
                    </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
