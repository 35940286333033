import React from 'react';

interface ProgressBarProps {
  quizId: number;
  progression: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { quizId, progression } = props;

  return (
    <div className="progress-bar">
      <div className={`step ${quizId >= 1 && 'accomplished'}`}>
        <div className="label">Welcome</div>
        <div className="indicator"></div>
      </div>
      <div className={`step ${quizId >= 2 && 'accomplished'}`} style={{left: '25%'}}>
        <div className="label">Exercise One</div>
        <div className="indicator"></div>
      </div>
      <div className={`step ${quizId >= 3 && 'accomplished'}`} style={{left: '50%'}}>
        <div className="label">Exercise Two</div>
        <div className="indicator"></div>
      </div>
      <div className={`step ${quizId >= 4 && 'accomplished'}`} style={{left: '75%'}}>
        <div className="label">Exercise Three</div>
        <div className="indicator"></div>
      </div>
      <div className={`step ${quizId === 5 && 'accomplished'}`} style={{left: '100%'}}>
        <div className="label">Thank You</div>
        <div className="indicator"></div>
      </div>
      <div className="progression" style={{width: `${progression}%`}}></div>
    </div>
  );
};
