import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { QuestionLabel } from '../../components/QuestionLabel';
import { QuestionOption } from '../interfaces';
import { QuestionProps } from './interfaces';
import { IconButton } from '../../components/IconButton';

import close from '../../assets/icons/icon-x-white.png';
import closeHover from '../../assets/icons/icon-x-orange.png';
import closeDisabled from '../../assets/icons/icon-x-light-grey.png';

export const SelectInline = React.memo((props: QuestionProps) => {
  const { question, answers, focus, onNext, onChange, show, handleClose, handleShow } = props;
  const { id, options, maxOptions } = question;

  const value: QuestionOption[] = answers || [];

  const onNextAfterValidation = useCallback(() => {
    if (value.length === maxOptions) {
      onNext(id);
    }
  }, [id, value, maxOptions, onNext]);

  useEffect(() => {
    if (!focus) {
      return;
    }

    document.addEventListener('keypress', listener);
    return () => document.removeEventListener('keypress', listener);

    function listener(evt: KeyboardEvent) {
      if (evt.target && (evt.target as any).classList.contains('btn-select-inline')) {
        return;
      }
      if (evt.key === 'Enter') {
        onNextAfterValidation();
      }
    }
  }, [focus, onNextAfterValidation]);

  const onChangeSelect = useCallback(
    (option: QuestionOption) => {
      const isSelected = value.some((x) => x.id === option.id);

      if (isSelected) {
        onChange(
          id,
          value.filter((x) => x.id !== option.id),
        );
      } else if (maxOptions === 1) {
        onChange(id, [{ id: option.id, value: option.value }]);
      } else {
        onChange(id, [...value, { id: option.id, value: option.value }]);
      }
    },
    [id, value, maxOptions, onChange],
  );

  return (
    <div className="quiz-content">
      <div className="quiz-content-question">
        <QuestionLabel question={question} />
        <div className={`select-inline d-flex d-md-inline-flex align-items-center justify-content-center ${value.length > 0 ? 'has-selection' : ''}`}>
          <div style={{ margin: '0 16px' }}>Select one...</div>
          {options.map((option) => {
            const isSelected = value.some((x) => x.id === option.id);
            const disabled = maxOptions > 1 && value.length >= maxOptions && !isSelected;
            return (
              <button
                className={`btn-select-inline ${isSelected ? 'is-selected' : ''} ${option.iconClass ? option.iconClass + ' has-icon' : ''}`}
                key={option.id}
                type="button"
                onClick={(evt) => {
                  onChangeSelect(option);
                }}
                disabled={disabled}
              >
                {option.value}
              </button>
            );
          })}
          <div className="line" />
        </div>

      </div>
      <div className="quiz-content-actions">
        <div className="my-3">
          <div className="row align-items-center">
            <div className="col-auto">
              <button className="btn-next" disabled={value.length !== maxOptions} type="button" onClick={onNextAfterValidation}>
               Next →
              </button>
            </div>
          </div>
        </div>
        {question.explanationText && (
          <>
            <Button className="btn-show-modal dark my-0 mx-auto mx-sm-0" onClick={handleShow} variant="link">{question.explanationText}</Button>
            <Modal className="explanation-modal" centered show={show} onHide={handleClose}>
              <Modal.Body className="bg-emperor text-white">
                <IconButton
                  classNames="btn-close-modal close-modal"
                  icon={close}
                  hoverIcon={closeHover}
                  disabled={false}
                  disabledIcon={closeDisabled}
                  onClickAction={handleClose}
                  btnAlt="Close"
                />
                <div className="explanation-text">
                  {question.explanationContent}
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
});
