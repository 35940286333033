import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { QuestionLabel } from '../../components/QuestionLabel';
import { IconButton } from '../../components/IconButton';

import close from '../../assets/icons/icon-x-white.png';
import closeHover from '../../assets/icons/icon-x-orange.png';
import closeDisabled from '../../assets/icons/icon-x-light-grey.png';
import check from '../../assets/icons/icon-check-white.png';
import checkHover from '../../assets/icons/icon-check-orange.png';
import checkDisabled from '../../assets/icons/icon-check-light-grey.png';
import upload from '../../assets/icons/icon-upload-orange.png';
import uploadDisabled from '../../assets/icons/icon-upload-light-grey.png';
import zoom from '../../assets/icons/icon-zoom-white.png';
import zoomHover from '../../assets/icons/icon-zoom-orange.png';
import zoomDisabled from '../../assets/icons/icon-zoom-light-grey.png';
import { QuestionOption } from '../interfaces';
import { QuestionProps } from './interfaces';

const UPLOAD_ID = -10;

export const SelectGridImage = React.memo((props: QuestionProps) => {
  const { question, answers, focus, onNext, onChange, show, handleClose, handleShow, image, uploadImage } = props;
  const { id, maxOptions } = question;
  const [options, setOptions] = useState<QuestionOption[]>(question.options)

  const value: QuestionOption[] = answers || [];

  const onNextAfterValidation = useCallback(() => {
    if (value.length === maxOptions) {
      onNext(id, value);
    }
  }, [id, value, maxOptions, onNext]);

  useEffect(() => {
    if (!focus) {
      return;
    }

    document.addEventListener('keypress', listener);
    return () => document.removeEventListener('keypress', listener);

    function listener(evt: KeyboardEvent) {
      if (evt.target && (evt.target as any).classList.contains('btn-select-inline')) {
        return;
      }
      if (evt.key === 'Enter') {
        onNextAfterValidation();
      }
    }
  }, [focus, onNextAfterValidation]);

  const onChangeSelect = useCallback(
    (option: QuestionOption) => {
      const isSelected = value.some((x) => x.id === option.id);

      if (isSelected) {
        onChange(
          id,
          value.filter((x) => x.id !== option.id),
        );
      } else if (maxOptions === 1) {
        onChange(id, [{ id: option.id, value: option.value }]);
      } else {
        onChange(id, [...value, { id: option.id, value: option.value }]);
      }
    },
    [id, value, maxOptions, onChange],
  );

  const onImageUpload = useCallback((file?: File | null) => {
    if (!file) {
      return;
    }
    try {
      uploadImage(file);
      const imageUrl = URL.createObjectURL(file);
      if(!options.find((o) => o.id === UPLOAD_ID)) {
        const uploadedOption = {
          id: UPLOAD_ID,
          label: '',
          value: imageUrl,
          thumbnail: imageUrl,
        };
        setOptions([...options, uploadedOption]);
        onChangeSelect(uploadedOption);
      }
    } catch (e) {
      console.error('Error uploading file', e);
    }
  }, [options, onChangeSelect, uploadImage]);

  const removeUpload = () => {
    uploadImage(undefined);
    setOptions(options.filter((option) => option.id !== UPLOAD_ID));
  };

  useEffect(() => {
    if (image) {
      if(!options.find((o) => o.id === UPLOAD_ID)) {
        const imageUrl = URL.createObjectURL(image);
        const uploadedOption = {
          id: UPLOAD_ID,
          label: '',
          value: imageUrl,
          thumbnail: imageUrl,
        };
        setOptions([...options, uploadedOption]);
      }
    }
  }, [options, image]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<QuestionOption>();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = useCallback((option: QuestionOption) => {
    setShowModal(true);
    setModalImage(option);
  }, [],);

  return (
    <>
      <div className="quiz-content">
        <div className="quiz-content-question">
        <QuestionLabel question={question} />
          <div className="select-grid-image">
            <div className="d-flex flex-wrap justify-content-around align-items-center">
              {options.map((option) => {
                const isSelected = value.some((x) => x.id === option.id);
                const disabled = maxOptions > 1 && value.length >= maxOptions && !isSelected;
                let handleChangeSelect = () => onChangeSelect(option);
                if (option.id === UPLOAD_ID) {
                  handleChangeSelect = () => {
                    onChangeSelect(option);
                    removeUpload();
                  };
                }
                return (
                  <GridImageWithHover
                    key={option.id}
                    option={option}
                    onChangeSelect={handleChangeSelect}
                    onShowFullSize={() => handleShowModal(option)}
                    disabled={disabled}
                    isSelected={isSelected} />
                  )
              })}
              {options.length <= 14 && (
                <GridImageUpload
                  disabled={value.length >= maxOptions}
                  onImageUpload={onImageUpload} />
              )}
            </div>
          </div>
        </div>
        <div className="quiz-content-actions">
          <div className="row align-items-center my-3">
            <div className="col-auto">
              <button className="btn-next" disabled={value.length !== maxOptions} type="button" onClick={onNextAfterValidation}>
               Next →
              </button>
            </div>
          </div>
          {question.explanationText && (
            <>
              <Button className="btn-show-modal dark my-0 mx-auto mx-sm-0" onClick={handleShow} variant="link">{question.explanationText}</Button>
              <Modal className="explanation-modal" centered show={show} onHide={handleClose}>
                <Modal.Body className="bg-emperor text-white">
                  <Button className="btn-close-modal" onClick={handleClose}>X</Button>
                  <div className="explanation-text">
                    {question.explanationContent}
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </div>
      </div>
      <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-50w">
        <IconButton
          classNames="btn-close-modal close-modal m-3"
          icon={close}
          hoverIcon={closeHover}
          disabled={false}
          disabledIcon={closeDisabled}
          onClickAction={handleCloseModal}
          btnAlt="Close"
        />
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={modalImage?.value} alt='Representation of Riyadh' className="w-100 h-100" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

interface GridImageWithHoverProps {
  option: QuestionOption;
  isSelected: boolean;
  onChangeSelect: (option: QuestionOption) => void;
  onShowFullSize: (option: QuestionOption) => void;
  disabled: boolean;
}

const GridImageWithHover = React.memo((props: GridImageWithHoverProps) => {
  const {option, isSelected, onChangeSelect, disabled, onShowFullSize} = props;
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div className='grid-image-container' onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {(isHover && !disabled) && (
        <div className='grid-image-hover d-flex align-items-center justify-content-around'>
          <IconButton
            icon={zoom}
            hoverIcon={zoomHover}
            disabled={disabled}
            disabledIcon={zoomDisabled}
            onClickAction={() => onShowFullSize(option)}
            btnAlt="Zoom"
          />
          {isSelected ? (
            <IconButton
              icon={close}
              hoverIcon={closeHover}
              disabled={disabled}
              disabledIcon={closeDisabled}
              onClickAction={() => onChangeSelect(option)}
              btnAlt="Close"
            />
          ) : (
            <IconButton
              icon={check}
              hoverIcon={checkHover}
              disabled={disabled}
              disabledIcon={checkDisabled}
              onClickAction={() => onChangeSelect(option)}
              btnAlt="Select"
            />
          )}
        </div>)
      }
      <div
        className="grid-image"
        style={{
          backgroundImage: `url(${option.thumbnail})`,
          outline: isSelected ? '3px solid black' : 'none',
          outlineOffset: '5px',
        }}
      >
      </div>
    </div>
  );
});

const MAX_IMG_SIZE = 1 * 1024 * 1024; // 1MB

interface GridImageUploadProps {
  onImageUpload: (file: File) => void;
  disabled: boolean;
}

const GridImageUpload = React.memo((props: GridImageUploadProps) => {
  const { onImageUpload, disabled } = props;
  const [errors, setErrors] = useState("");
  const {acceptedFiles, getRootProps, getInputProps} =
    useDropzone({
      disabled: disabled,
      maxSize: MAX_IMG_SIZE,
      maxFiles: 1,
      accept: 'image/png, image/jpeg',
      onDrop: (acceptedFiles, fileRejections) => {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              // Manually set this error message to use '1MB' in place of '1048576 bytes'
              setErrors('Error: File cannot be larger than 1MB');
            }
  
            if (err.code === "file-invalid-type") {
              setErrors(`Error: ${err.message}`);
            }
          });
        });
      }
    });

  // eslint-disable-next-line array-callback-return
  acceptedFiles.map((file) => {
    onImageUpload(file);
  })

  return (
    <section className="grid-image-container">
      <div {...getRootProps({className: `image-dropzone ${disabled ? 'disabled' : ''}`})}>
        <input {...getInputProps()} />
        <div className="mb-2 upload-you-own">Want to upload your own?</div>
        <div className="d-flex flex-row">
          <div>
            {disabled ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img src={uploadDisabled} alt="Upload Image" />
            ) : (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img src={upload} alt="Upload Image" />
            )}
          </div>
          <span className="choose-your-own">
            <div className="choose-file">Choose a file</div>
            <div>or drag it here</div>
          </span>
        </div>
        <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
          {errors}
        </p>
      </div>
    </section>
  );
});
