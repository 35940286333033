import React from 'react';
import Button from 'react-bootstrap/Button';

import arrowDown from '../assets/icons/arrow-down.svg';
import { IntroProps } from './interfaces';

export const IntroWelcome = React.memo((props: IntroProps) => {
  const { onShowQuiz, handleShow, watched } = props;
  
  let displayComponent = null;
  if (watched) {
    displayComponent = (
      <div className="btn-start-quiz-container" onClick={onShowQuiz}>
        <div>Click Here To Begin</div>
        <button className="btn-start-quiz mt-3" type="button">
          <img src={arrowDown} width="70" alt="Down arrow to start quiz"/>
        </button>
      </div>
    );
  } else {
    displayComponent = (
      <Button className="btn-show-modal btn-transparent" onClick={handleShow}>
        Start Here <span className="play-symbol"></span>
      </Button>
    )
  }

  return (
    <div className="container text-center text-gutters">
      <h1 className="text-uppercase title-text text-gold text-bold">welcome to the future of riyadh!</h1>
      <div className="intro-text">
        To shape the future of our great city, we first need to hear from you. We’re asking only 100 people to share
        their opinion, so your voice will play an important role in helping our leaders map our next steps forward.
        Let’s get started!
      </div>
      <div> 
        {displayComponent}
      </div>
    </div>
  );
});
