import React from 'react';

import { ProgressBar } from './ProgressBar';

import logo from '../assets/rc-logo.png';

interface HeaderProps {
  quizId: number;
  progression: number;
}

export const Header = (props: HeaderProps) => {
  const { quizId, progression } = props;

  return (
    <header>
      <div className="container d-flex justify-content-between align-items-center flex-wrap">
        <img className="city-logo" src={logo} alt="Riyadh City Emblem" />
        <ProgressBar progression={progression} quizId={quizId} />
      </div>
    </header>
  );
};
