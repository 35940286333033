import React from 'react';

import { AllAnswers } from '../App';

interface SummaryProps {
  answers: AllAnswers;
}

export const IntroSummary = React.memo((props: SummaryProps) => {
  const { answers } = props;

  console.log("All answers: ", JSON.stringify(answers));

  return (
    <div className="container text-center text-gutters">
      <h1 className="text-uppercase title-text text-gold text-bold">Thank you!</h1>
      <h1 className="text-uppercase title-text text-gold text-bold"> Your answers have been submitted.</h1>
      <div className="intro-text">
      Your participation in this workshop today will help our leadership create a better, brighter future for our great city of Riyadh. The Kingdom appreciates your time and candor. Have a great day!
      </div>
    </div>
  );
});
