import React from 'react';
import Button from 'react-bootstrap/Button';

import arrowDown from '../assets/icons/arrow-down.svg';
import { IntroProps } from './interfaces';

export const IntroQuiz2 = React.memo((props: IntroProps) => {
  const { onShowQuiz, handleShow } = props;

  return (
    <div className="container text-center text-gutters">
      <h1 className="text-uppercase title-text text-gold text-bold">Why Riyadh?</h1>
      <div className="intro-text">
      In this exercise, you’ll be faced with different types of people with different backgrounds and goals and asked to explain why they should choose Riyadh as a place to work, learn, live, or visit
      </div>
      <div>
        <Button className="btn-show-modal" onClick={handleShow}>
          Learn More About This Exercise <span className="play-symbol"></span>
        </Button>
      </div>
      <div> 
        <div className="btn-start-quiz-container" onClick={onShowQuiz}>
          <div>Click Here To Begin</div>
          <button className="btn-start-quiz mt-3" type="button">
            <img src={arrowDown} width="70" alt="Down arrow to start quiz"/>
          </button>
        </div>
      </div>
    </div>
  );
});
