import { Quiz } from './interfaces';

export const WelcomeQuiz: Quiz = {
  id: 1,
  name: 'Quiz 1',
  videoUrl: 'https://player.vimeo.com/video/380508727',
  backgroundImage: require('../assets/background-images/welcome.jpg'),
  header: 'Welcome! We\'re glad you\'re here!',
  thankYouHeadline: 'Thanks, ',
  nextScreenMessage: 'Next, we’ll ask a series of questions that will help us discover what makes Riyadh appealing to different types of people. This should take approximately 5-10 minutes.',
  questions: [
    {
      id: 1,
      heading: "What name do you go by?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 100,
      showCount: false,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'Why are you asking this?',
      explanationContent: 'To prevent duplicate submissions, we ask you to provide a few identifying answers. Your information will be kept anonymous and not shared with or sold to any other parties.'
    },
    {
      id: 2,
      showUserName: true,
      heading: "What's your occupation?",
      subHeading: '',
      inputType: 'dropdown',
      placeholder: '',
      maxOptions: 1,
      options: [
        {
          id: 1,
          value: 'Choose your occupation...',
        },
        {
          id: 2,
          value: "Agriculture and Farming",
        },
        {
          id: 3,
          value: "Arts, Entertainment, and Culture",
        },
        {
          id: 4,
          value: "Business Management, Finance, and Administration",
        },
        {
          id: 5,
          value: "Craftsman or Tradesman",
        },
        {
          id: 6,
          value: "Education and Training",
        },
        {
          id: 7,
          value: "Engineering and Technology",
        },
        {
          id: 8,
          value: "Entrepreneurship and Business Ownership",
        },
        {
          id: 9,
          value: "Government and Public Administration",
        },
        {
          id: 10,
          value: "Healthcare",
        },
        {
          id: 11,
          value: "Hospitality and Tourism",
        },
        {
          id: 12,
          value: "Household Caretaker",
        },
        {
          id: 13,
          value: "Journalism, Writing, and News Reporting",
        },
        {
          id: 14,
          value: "Law and Public Safety",
        },
        {
          id: 15,
          value: "Manufacturing",
        },
        {
          id: 16,
          value: "Religious Leadership",
        },
        {
          id: 17,
          value: "Royal Saudi Land Forces",
        },
        {
          id: 18,
          value: "Sales and Service Industry",
        },
        {
          id: 19,
          value: "Student",
        },
        {
          id: 20,
          value: "Transportation and Public Infrastructure",
        },
        {
          id: 21,
          value: "Other",
        },
      ],
      explanationText: 'Why are you asking this?',
      explanationContent: 'To prevent duplicate submissions, we ask you to provide a few identifying answers. Your information will be kept anonymous and not shared with or sold to any other parties.'
    },
    {
      id: 3,
      heading: "What's your age?",
      subHeading: '',
      inputType: 'select-inline',
      placeholder: '',
      maxOptions: 1,
      options: [
        {
          id: 1,
          value: 'Under 18',
        },
        {
          id: 2,
          value: '18-29',
        },
        {
          id: 3,
          value: '30-45',
        },
        {
          id: 4,
          value: '46-55',
        },
        {
          id: 5,
          value: '56-65',
        },
        {
          id: 6,
          value: '65 & up',
        },
      ],
      explanationText: 'Why are you asking this?',
      explanationContent: 'To prevent duplicate submissions, we ask you to provide a few identifying answers. Your information will be kept anonymous and not shared with or sold to any other parties.'
    },
    {
      id: 4,
      heading: "What's your gender?",
      subHeading: '',
      inputType: 'select-inline',
      placeholder: '',
      maxOptions: 1,
      options: [
        {
          id: 1,
          value: 'Male',
          iconClass: 'male',
        },
        {
          id: 2,
          value: 'Female',
          iconClass: 'female',
        },
      ],
      explanationText: 'Why are you asking this?',
      explanationContent: 'To prevent duplicate submissions, we ask you to provide a few identifying answers. Your information will be kept anonymous and not shared with or sold to any other parties.'
    },
  ],
};

export const Quiz1: Quiz = {
  id: 2,
  name: 'Quiz 2',
  videoUrl: 'https://player.vimeo.com/video/380508727',
  backgroundImage: require('../assets/background-images/quiz1.jpg'),
  header: 'Who is Riyadh?',
  thankYouHeadline: 'Wonderful! Thank you, ',
  nextScreenMessage: 'Next, we’ll ask a series of questions that will help us discover what makes Riyadh appealing to different types of people. This should take approximately 5-10 minutes.',
  questions: [
    {
      id: 1,
      heading: "If Riyadh was a member of your family, who would they be?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      showCount: false,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'If you were to compare the spirit of Riyadh with someone in your family, is it like your mother, a younger sibling, or maybe a grandparent?',
    },
    {
      id: 2,
      showPrevQuestion: true,
      heading: "Interesting! What’s the most important lesson you would learn from them?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Think about the family member you compared to Riyadh. What important lessons or values would you learn from them? Maybe perseverance or honesty? Perhaps generosity or respect?',
    },
    {
      id: 3,
      heading: "If you were leading a tour group of first-time visitors to Riyadh, what would you show them first?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Think about the locations in Riyadh you would most want people to visit. It could be your favorite park or musuem, or maybe a historical site or education center. Consider places you believe best represent your city.',
    },
    {
      id: 4,
      heading: "Got it! What about that place reflects what’s special about Riyadh?",
      showPrevQuestion: true,
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Consider the characteristics of the place you chose, and how it captures the essence of Riyadh. Could it be symbolic of the community, the culture, or perhaps the history or future of the city?',
    },
    {
      id: 5,
      heading: "If Riyadh was an animal, what would it be?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Imagine a creature you believe shares similiar qualities with Riyadh. It could be a lion, a horse, or maybe even a octopus. Get creative!',
    },
    {
      id: 6,
      showPrevQuestion: true,
      heading: "Great! What characteristics of that animal do you believe Riyadh shares?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Think about the key characteristics of the animal you chose and why it reminds you of Riyadh. If you picked a lion, for example, perhaps its strength and leadership are qualities you see in Riyadh.',
    },
    {
      id: 7,
      heading: "If Riyadh never existed, what would the world lose?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'If Riyadh were gone tomorrow, what would the world miss most? Perhaps it would be the brilliance of its people, or historic arts and culture?',
    },
    {
      id: 8,
      showPrevQuestion: true,
      heading: "Awesome! Can you tell me more about why you said that?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Think about what you said the world would miss if Riyadh never existed, and why those things matter. If you chose the brilliance of the people, for example, perhaps the world would lose many positive future contributions those people would have made.',
    },
    {
      id: 9,
      heading: "If Riyadh built a time capsule, what three items would they include?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'Consider anything from people, places, or things you believe would be critial options to include in a Riyadh time capsule. It could be anything from Saudi artwork to family recipes, so long as you believe it reflects the spirit of the city.',
    },
    {
      id: 10,
      showPrevQuestion: true,
      heading: "Good choices! What about those items are important to Riyadh?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
      explanationText: 'What is a good example?',
      explanationContent: 'How do each of the things you chose embody the city of Riyadh? Why are they important to preserve? Perhaps they reflect Riyadh\'s resillience, prosperity, or maybe its sense of community?',
    },
  ],
};

export const Quiz2: Quiz = {
  id: 3,
  name: 'Quiz 3',
  videoUrl: 'https://player.vimeo.com/video/380508727',
  backgroundImage: require('../assets/background-images/quiz2.jpg'),
  header: 'Why Riyadh?',
  thankYouHeadline: 'You\'re doing great, ',
  nextScreenMessage: 'Next, we’ll ask a few questions to help us determine the visual identity of Riyadh. This should take approximately 3-5 minutes.',
  questions: [
    {
      id: 1,
      heading: "A young couple who just completed their MBAs are thinking of moving to Saudi Arabia. What will convince them to choose Riyadh?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      imageUrl: require('../assets/question-images/mba-couple.jpg'),
      maxLength: 500,
      showCount: true,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
    {
      id: 2,
      heading: "A start-up founder is considering growing their business in Dubai or Riyadh. Why should they choose Riyadh?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      imageUrl: require('../assets/question-images/start-up-founder.jpg'),
      maxLength: 500,
      showCount: true,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
    {
      id: 3,
      heading: "A student is considering universities in the Middle East. What would make Riyadh their first choice in location?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      imageUrl: require('../assets/question-images/student.jpg'),
      maxLength: 500,
      showCount: true,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
    {
      id: 4,
      heading: "A newly retired couple is planning a trip to the Middle East. Why should they pick Riyadh as a destination?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      imageUrl: require('../assets/question-images/retired-couple.jpg'),
      maxLength: 500,
      showCount: true,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
    {
      id: 5,
      heading: "A couple with two young children is looking for a place to raise a happy, healthy family. What makes Riyadh the best place for them?",
      subHeading: '',
      inputType: 'input',
      placeholder: 'Type your answer here...',
      maxOptions: 1,
      imageUrl: require('../assets/question-images/family.jpg'),
      maxLength: 500,
      showCount: true,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
  ],
};

export const Quiz3: Quiz = {
  id: 4,
  name: 'Quiz 4',
  videoUrl: 'https://player.vimeo.com/video/380508727',
  backgroundImage: require('../assets/background-images/quiz3.jpg'),
  header: 'Create a moodboard',
  thankYouHeadline: 'Perfect! Your moodboard looks great, ',
  nextScreenMessage: 'Nice work, you’ve reached the end of the Future of Riyadh workshop. If you’d like to see your moodboard before you lock in your choices, click the button below. Or, if you’re ready to finalize your answers, click submit to complete the survey.',
  questions: [
    {
      id: 1,
      heading: "Choose 2-4 words that you think best represent Riyadh",
      subHeading: '',
      inputType: 'select-grid-text',
      placeholder: '',
      minOptions: 2,
      maxOptions: 4,
      options: [
        {
          id: 1,
          value: 'Innovative',
        },
        {
          id: 2,
          value: 'Family-oriented',
        },
        {
          id: 3,
          value: 'Proud',
        },
        {
          id: 4,
          value: 'Wise',
        },
        {
          id: 5,
          value: 'Strong',
        },
        {
          id: 6,
          value: 'Beautiful',
        },
        {
          id: 7,
          value: 'Unique',
        },
        {
          id: 8,
          value: 'Passionate',
        },
        {
          id: 9,
          value: 'Courageous',
        },
        {
          id: 10,
          value: 'Exciting',
        },
        {
          id: 11,
          value: 'Spiritual',
        },
        {
          id: 12,
          value: 'Connected',
        },
        {
          id: 13,
          value: 'Confident',
        },
        {
          id: 14,
          value: 'Diverse',
        },
        {
          id: 15,
          value: 'Generous',
        },
        {
          id: 16,
          value: 'Resourceful',
        },
        {
          id: 17,
          value: 'Creative',
        },
        {
          id: 18,
          value: 'Easy-going',
        },
        {
          id: 19,
          value: 'Dependable',
        },
        {
          id: 20,
          value: 'Welcoming',
        },
      ],
    },
    {
      id: 2,
      heading: "Choose 2 colors that best reflect the spirit of Riyadh",
      subHeading: '',
      inputType: 'select-grid-color',
      placeholder: '',
      maxOptions: 2,
      options: [
        {
          id: 1,
          value: '',
          hexColor: '#BF0436',
        },
        {
          id: 2,
          value: '',
          hexColor: '#F2C53D',
        },
        {
          id: 3,
          value: '',
          hexColor: '#A69898',
        },
        {
          id: 4,
          value: '',
          hexColor: '#8C4E03',
        },
        {
          id: 5,
          value: '',
          hexColor: '#031E8C',
        },
        {
          id: 6,
          value: '',
          hexColor: '#68A0A6',
        },
        {
          id: 7,
          value: '',
          hexColor: '#4A5932',
        },
        {
          id: 8,
          value: '',
          hexColor: '#D9BA82',
        },
        {
          id: 9,
          value: '',
          hexColor: '#038C5A',
        },
        {
          id: 10,
          value: '',
          hexColor: '#D9806C',
        },
      ],
    },
    {
      id: 3,
      heading: "Choose 3 images that you think best represent Riyadh",
      subHeading: '',
      inputType: 'select-grid-image',
      placeholder: '',
      maxOptions: 3,
      options: [
        {
          id: 1,
          value: require('../assets/images/Enlarged_01.jpg'),
          thumbnail: require('../assets/images/Thumbnail_01.jpg'),
        },
        {
          id: 2,
          value: require('../assets/images/Enlarged_02.jpg'),
          thumbnail: require('../assets/images/Thumbnail_02.jpg'),
        },
        {
          id: 3,
          value: require('../assets/images/Enlarged_03.jpg'),
          thumbnail: require('../assets/images/Thumbnail_03.jpg'),
        },
        {
          id: 4,
          value: require('../assets/images/Enlarged_04.jpg'),
          thumbnail: require('../assets/images/Thumbnail_04.jpg'),
        },
        {
          id: 5,
          value: require('../assets/images/Enlarged_05.jpg'),
          thumbnail: require('../assets/images/Thumbnail_05.jpg'),
        },
        {
          id: 6,
          value: require('../assets/images/Enlarged_06.jpg'),
          thumbnail: require('../assets/images/Thumbnail_06.jpg'),
        },
        {
          id: 7,
          value: require('../assets/images/Enlarged_07.jpg'),
          thumbnail: require('../assets/images/Thumbnail_07.jpg'),
        },
        {
          id: 8,
          value: require('../assets/images/Enlarged_08.jpg'),
          thumbnail: require('../assets/images/Thumbnail_08.jpg'),
        },
        {
          id: 9,
          value: require('../assets/images/Enlarged_09.jpg'),
          thumbnail: require('../assets/images/Thumbnail_09.jpg'),
        },
        {
          id: 10,
          value: require('../assets/images/Enlarged_10.jpg'),
          thumbnail: require('../assets/images/Thumbnail_10.jpg'),
        },
        {
          id: 11,
          value: require('../assets/images/Enlarged_11.jpg'),
          thumbnail: require('../assets/images/Thumbnail_11.jpg'),
        },
        {
          id: 12,
          value: require('../assets/images/Enlarged_12.jpg'),
          thumbnail: require('../assets/images/Thumbnail_12.jpg'),
        },
        {
          id: 13,
          value: require('../assets/images/Enlarged_13.jpg'),
          thumbnail: require('../assets/images/Thumbnail_13.jpg'),
        },
        {
          id: 14,
          value: require('../assets/images/Enlarged_14.jpg'),
          thumbnail: require('../assets/images/Thumbnail_14.jpg'),
        },
      ],
    },
    {
      id: 4,
      heading: 'Is there anything else you want us to know about the city of Riyadh?',
      subHeading: '',
      inputType: 'input',
      placeholder: '',
      maxOptions: 3,
      maxLength: 500,
      options: [
        {
          id: 1,
          value: '',
        },
      ],
    },
  ],
};
