import React from 'react';

import { Question } from '../quiz/interfaces';

interface QuestionLabelProps {
  question: Question;
}

export const QuestionLabel = React.memo((props: QuestionLabelProps) => {
  const { question } = props;
  
  return (
    <label>
      <div className="question-heading">
        {question.imageUrl && <img src={question.imageUrl} className="img-fluid" style={{maxWidth: '335px'}} alt="Question associated"/>}
        <div className="heading-text">{question.heading}</div>
      </div>
      <p>{question.subHeading}</p>
    </label>
  )
})
