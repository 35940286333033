import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import lodashCloneDeep from 'lodash.clonedeep';
import { animated, useTransition } from 'react-spring';
import TextareaAutosize from 'react-autosize-textarea';

import { Moodboard } from '../components/Moodboard';
import { AllAnswers, QuizAnswers } from '../App';
import { Question, QuestionOption, Quiz } from './interfaces';
import { Dropdown } from './question-types/Dropdown';
import { Input } from './question-types/Input';
import { QuestionProps } from './question-types/interfaces';
import { SelectGridColor } from './question-types/SelectGridColor';
import { SelectGridImage } from './question-types/SelectGridImage';
import { SelectGridText } from './question-types/SelectGridText';
import { SelectInline } from './question-types/SelectInline';
import { IconButton } from '../components/IconButton';
import close from '../assets/icons/icon-x-white.png';
import closeHover from '../assets/icons/icon-x-orange.png';
import closeDisabled from '../assets/icons/icon-x-light-grey.png';

import firebase from '../config/firebaseConfig';

interface QuizProps {
  quiz?: Quiz;
  allAnswers: AllAnswers;
  currentPage: number;
  onAnswersChange: (answers: QuizAnswers) => void;
  onNextPage: () => void;
  updateProgression: () => void;
  showVideo: () => void;
}

const THANK_YOU_ID = -2;

export const QuizPanel = React.memo((props: QuizProps) => {
  const { quiz, allAnswers, currentPage, onAnswersChange, onNextPage, updateProgression, showVideo } = props;

  const [show, setShow] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | undefined>();
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [userName, setUserName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const questions = quiz?.questions || [];

  const questionMap = useMemo<{ [key: number]: Question }>(() => {
    const map: { [key: number]: Question } = {};
    questions.forEach((x) => (map[x.id] = x));
    return map;
  }, [questions]);

  const [currentQuestion, setCurrentQuestion] = useState<{ id?: number; direction: 'forward' | 'backward' }>({
    id: questions[0].id,
    direction: 'forward',
  });

  const [focusedQuestionId, setFocusedQuestionId] = useState<number | undefined>(questions[0].id);

  useEffect(() => {
    setCurrentQuestion({ id: questions[0].id, direction: 'forward' });
    setFocusedQuestionId(questions[0].id);
  }, [questions]);

  useEffect(() => {
    if (allAnswers && allAnswers[0] && allAnswers[0][1] && allAnswers[0][1][0] && allAnswers[0][1][0].value) {
      setUserName(allAnswers[0][1][0].value);
    }
  }, [allAnswers])

  const onChange = useCallback(
    (questionId: number, value: QuestionOption[]) => {
      onAnswersChange({
        ...allAnswers[currentPage],
        [questionId]: value,
      });
    },
    [allAnswers, onAnswersChange, currentPage],
  );

  // The callback that gets call after a question is answered
  const onNext = useCallback(
    (questionId: number, value: QuestionOption[]) => {
      setFocusedQuestionId(undefined);
      const currentIndex = questions.findIndex((x) => x.id === questionId);

      // If last question, show thank you page?
      if (currentIndex === questions.length - 1) {
        setCurrentQuestion({
          id: THANK_YOU_ID,
          direction: 'forward',
        });
      } else if (currentIndex > -1 && currentIndex + 1 < questions.length) {
        setCurrentQuestion({
          id: questions[currentIndex + 1].id,
          direction: 'forward',
        });
      }
      updateProgression();
    },
    [questions, updateProgression],
  );

  // The callback that gets call after a question is answered
  const onBack = useCallback(() => {
    const currentIndex = questions.findIndex((x) => x.id === currentQuestion.id);
    if (currentIndex > -1 && currentIndex - 1 >= 0) {
      setCurrentQuestion({
        id: questions[currentIndex - 1].id,
        direction: 'backward',
      });
    } else if (currentIndex < 0) {
      setCurrentQuestion({
        id: questions[questions.length - 1].id,
        direction: 'backward',
      })
    }
  }, [currentQuestion, questions]);

  const transitions: any = (useTransition as any)(currentQuestion.id, null, {
    from: {
      opacity: 0,
      transform: currentQuestion.direction === 'forward' ? 'translate3d(0, 100%, 0)' : 'translate3d(0, -100%, 0)',
    },
    enter: (questionId: number) => async (next: any) => {
      await next({ opacity: 1, transform: 'translate3d(0, 0, 0)' });
      setFocusedQuestionId(questionId);
    },
    leave: {
      opacity: 0,
      transform: currentQuestion.direction === 'forward' ? 'translate3d(0, -100%, 0)' : 'translate3d(0, 100%, 0)',
    },
  });

  const handleUploadImage = (file?: File) => {
    setUploadedImage(file);
  }

  const submitQuiz = () => {
    console.log('Quiz Submitted');
    onNextPage();

    // Comment out upload logic for now
    // // Copy allAnswers to new object
    // let answers = lodashCloneDeep(allAnswers);

    // // Check for client uploaded image file, if none just upload quiz answers 
    // if (uploadedImage) {
    //   let storageRef = firebase.storage().ref();

    //   const name = new Date() + '-client-image';

    //   const metadata = {
    //     contentType: uploadedImage.type,
    //   }

    //   const uploadTask = storageRef.child(name).put(uploadedImage, metadata);

    //   uploadTask.on('state_changed', snapshot => {
    //     setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //   }, err => {
    //     console.error("Error adding document: ", err);
    //   }, () => {
    //     uploadTask.snapshot.ref.getDownloadURL().then(url => {
    //       console.log(url);
    //       let uploadedIndex = answers[3][3].findIndex(option => {
    //         return option.id === -10;
    //       })
    //       if (uploadedIndex > -1) {
    //         answers[3][3][uploadedIndex].value = url;
    //         console.log('Answers with uploaded image URL', JSON.stringify(answers));
    //         uploadQuiz(answers);
    //       } else {
    //         console.log('Uploaded image index could not be found');
    //       }
    //     });
    //   });
    // } else {
    //   uploadQuiz(answers);
    // }
  }

  const uploadQuiz = (answers: AllAnswers) => {
    // let db = firebase.firestore();

    // db.collection('brighthouse-riyadh-results').add({answers})
    //   .then(() => {
    //     onNextPage();
    //   })
    //   .catch(function(error) {
    //       console.error("Error adding document: ", error);
    //   });
  }

  const onAddtionalInfo = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(0, [{ id: 1, value: evt.target.value }]);
  }

  return (
    <>
      <div className="quiz-header">
        <div className="container d-flex justify-content-between flex-wrap align-items-center">
          <h3 className="text-white my-3 mx-auto mx-sm-0">{quiz?.header}</h3>
          <Button className="btn-show-modal my-0 mx-auto mx-sm-0" onClick={showVideo}>
            Rewatch Video <span className="play-symbol"></span>
          </Button>
        </div>
      </div>
      <div className="quiz-slides container">
        {transitions.map(({ item, props, key }: any) => {
          const q = questionMap[item];

          let prevQuestionLabel = '';
          
          let prevQuestionAnswer = '';

          if (item - 1 > 0) {
            const prevQuestion = questionMap[item - 1];
            prevQuestionLabel = prevQuestion.heading;
            if (allAnswers[currentPage] && allAnswers[currentPage][prevQuestion.id] && allAnswers[currentPage][prevQuestion.id].length > 0 ) {
              prevQuestionAnswer = allAnswers[currentPage][prevQuestion.id][0].value
            }
          }

          const questionComponents: { [key: string]: React.FC<QuestionProps> } = {
            input: Input,
            dropdown: Dropdown,
            'select-grid-color': SelectGridColor,
            'select-grid-image': SelectGridImage,
            'select-grid-text': SelectGridText,
            'select-inline': SelectInline,
          };

          const QuestionComponent: React.FC<QuestionProps> | null = q ? questionComponents[q.inputType] : null;

          return (
            <animated.div key={key} style={props} className="quiz-slide quiz-content">
              {(item === THANK_YOU_ID || q.id === 0) && (
                <div className="quiz-thank-you">
                  <button className="btn-quiz-back" type="button" onClick={onBack}>Previous ↑</button>
                  <h1>{quiz?.thankYouHeadline ? quiz.thankYouHeadline : 'Wonderful! Thank you, '}{userName ? userName : 'we appreciate it'}!</h1>
                  <p className="end-quiz-message">{quiz?.nextScreenMessage ? quiz.nextScreenMessage : ''}</p>
                  {currentPage === 3 ? 
                    <>
                      <div className="d-flex justify-content-center flex-wrap">
                        <button className="btn-next m-3" type="button" onClick={handleShow}>
                          See Your Moodboard
                        </button>
                        <button className="btn-next m-3" type="button" onClick={submitQuiz}>
                          {uploadProgress && uploadedImage ? `${uploadProgress}%` : 'Submit My Survey'}
                        </button>
                      </div>
                      <Modal centered className="moodboard-modal" show={show} onHide={handleClose}>
                        <Modal.Body>
                          <IconButton
                            classNames="btn-close-modal close-modal m-3"
                            icon={close}
                            hoverIcon={closeHover}
                            disabled={false}
                            disabledIcon={closeDisabled}
                            onClickAction={handleClose}
                            btnAlt="Close"
                          />
                          <Moodboard answers={allAnswers[currentPage]} />
                        </Modal.Body>
                      </Modal>
                    </> :
                    <div className="d-flex justify-content-center">
                      <button className="btn-next px-5" type="button" onClick={onNextPage}>
                        Show Next Exercise
                      </button>
                    </div>
                  }
                  
                </div>
              )}
              {q && q.id > 0 && <div className="question-count"># {q.id} of {questions.length}</div>}
              {q && q.id > 1 && <button className="btn-quiz-back" type="button" onClick={onBack}>Previous ↑</button>}
              {QuestionComponent && q.id !== 0 ? (
                <QuestionComponent
                  question={q}
                  prevQuestionLabel={prevQuestionLabel}
                  prevQuestionAnswer={prevQuestionAnswer}
                  answers={allAnswers[currentPage] && allAnswers[currentPage][q.id] ? allAnswers[currentPage][q.id] : undefined}
                  focus={focusedQuestionId === q.id}
                  onNext={onNext}
                  onChange={onChange}
                  show={show}
                  handleShow={handleShow}
                  handleClose={handleClose}
                  uploadImage={handleUploadImage}
                  image={uploadedImage}
                  userName={userName}
                />
              ) : ''}
            </animated.div>
          );
        })}
      </div>
    </>
  );
});
